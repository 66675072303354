import React from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Link from "@mui/material/Link";

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }
    return (
        <div className="selector">
            {i18n.language === 'en' ? (
                <Link onClick={() => changeLanguage('ka')} style={{height: "35px"}}><img style={{width: "40px"}} src="./Flag_of_Georgia.png" alt="KA"/></Link>
            ) : (
                <Link onClick={() => changeLanguage('en')} style={{height: "35px"}}><img style={{width: "40px"}} src="./usa-flag.png" alt="EN"/></Link>
            )}
        </div>
    )
}

export default LanguageSelector;