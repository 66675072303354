import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useTranslation } from 'react-i18next';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const { t } = useTranslation();

    const images = [
        {
            label: t('carouselUg'),
            imgPath: 'UG.jpg',
        },
        {
            label: t('carouselFirst'),
            imgPath: 'FIRST.jpg',
        },
        {
            label: t('carouselNasa'),
            imgPath: 'NASA.jpg',
        },
        {
            label: t('carouselCern'),
            imgPath: 'CERN.JPG',
        },
    ];

    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 'auto',
                    pl: 2,
                    bgcolor: 'background.default',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                }}
            >
                <Typography>{images[activeStep].label}</Typography>
            </Paper>
            <div
                style={{
                    overflow: 'hidden',
                    maxHeight: '80vh',
                }}
            >
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {images.map((step, index) => (
                        <div key={step.label} style={{ height: '100%' }}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <Box
                                    component="img"
                                    src={step.imgPath}
                                    alt={step.label}
                                    sx={{
                                        display: 'block',
                                        width: '100%',
                                        maxHeight: '80vh',
                                        objectFit: 'cover',
                                        margin: 0,
                                        padding: 0,
                                        '@media (max-width: 1024px)': {
                                            height: '100%',
                                        },
                                    }}
                                />
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
            </div>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    );
}

export default SwipeableTextMobileStepper;
