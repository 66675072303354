import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import LanguageSelector from "./languageSelector";

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const { t } = useTranslation();

    const pages = [t("about"), t("members"), 'Blog'];
    const pageHref = ["about-us", "team", "news"];

    return (
        <AppBar position="static" style={{backgroundColor: "#51a8ff"}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src="logo.svg" alt="AI-IA Logo" style={{height: "100px"}}/>
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <MenuItem onClick={handleCloseNavMenu} href={'#about-us'}>
                                <Typography textAlign="center" component="a" style={{textDecoration: "none", color: "black"}} href={`#about-us`}>{t("about")}</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <Typography textAlign="center" component="a" style={{textDecoration: "none", color: "black"}} href={`#team`}>{t("members")}</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <Typography textAlign="center" component="a" style={{textDecoration: "none", color: "black"}} href={`#news`}>{t("news")}</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu} href="#contact">
                                <Typography textAlign="center" component="a" style={{textDecoration: "none", color: "black"}} href={`#contact`}>{t("contactHead")}</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src="logo.svg" alt="AI-IA Logo" style={{height: "100px"}}/>
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <div className="nav-text" style={{textAlign: "center", margin: "auto"}}>
                            <Link
                                href={`#about-us`}
                                underline="none"
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {t("about")}
                            </Link>
                        </div>
                        <div className="nav-text" style={{textAlign: "center", margin: "auto"}}>
                            <Link
                                href={`#team`}
                                underline="none"
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {t("members")}
                            </Link>
                        </div>
                        <div className="nav-text" style={{textAlign: "center", margin: "auto"}}>
                            <Link
                                href={`#news`}
                                underline="none"
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {t("news")}
                            </Link>
                        </div>
                        <div className="nav-text" style={{textAlign: "center", margin: "auto"}}>
                            <Link
                                href={`#contact`}
                                underline="none"
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {t("contactHead")}
                            </Link>
                        </div>
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <LanguageSelector/>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
