import React from 'react';
import {useTranslation} from "react-i18next";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import IconButton from '@mui/material/IconButton';

function ContactUs() {
    const { t } = useTranslation();
    return <>
        <div className="contact-container" id="contact">
            <h1 style={{textAlign: "center"}}>{t("contactHead")}</h1>
            <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d744.4596536216665!2d44.778031769654575!3d41.72400119820369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x404472da123b4ea7%3A0x50e3f823441705df!2zNzcg4YOb4YOU4YOg4YOQ4YORIOGDmeGDneGDoeGDouGDkOGDleGDkOGDoSDhg6Xhg6Phg6nhg5AsIOGDl-GDkeGDmOGDmuGDmOGDoeGDmA!5e0!3m2!1ska!2sge!4v1714840171378!5m2!1ska!2sge" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="contact-general">
                <div className="working-hours">
                    <p className="days"><b>{t("monday")}</b> 10:00-18:00</p>
                    <p className="days"><b>{t("tuesday")}</b> 10:00-18:00</p>
                    <p className="days"><b>{t("wednesday")}</b> 10:00-18:00</p>
                    <p className="days"><b>{t("thursday")}</b> 10:00-18:00</p>
                    <p className="days"><b>{t("friday")}</b> 10:00-18:00</p>
                    <p className="days"><b>{t("saturday")}</b> 12:00-17:00</p>
                    <p className="days"><b>{t("sunday")} </b> {t("closed")}</p>
                </div>
                <div className="contact-info">
                    <p>{t("socNet")}</p>
                    <a target={"_blank"} href="https://www.facebook.com/AI.IA.GEO">
                        <IconButton aria-label="Facebook Page">
                            <FacebookOutlinedIcon style={{color: "blue", fontSize: "30px"}}/>
                        </IconButton>
                    </a>
                    <p>{t("name")}</p>
                    <p>{t("email")}: <a href="mailto:info@a-innovations.org">info@a-innovations.org</a></p>
                    <p>{t("mobile")}: <a href="tel:+995568835577">+995 568 83 55 77</a></p>
                </div>
            </div>
        </div>
    </>
}

export default ContactUs;