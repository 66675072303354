import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    lng: 'ka',
    resources: {
        ka: {
            translation: {
                name: "ა(ა)იპ ინოვაციების ასოციაცია",

                about: "ჩვენი მისია",
                members: "გუნდის წევრები",

                aboutCompany: "ჩვენი მისიაა STEM (მეცნიერება, ტექნოლოგია, ინჟინერია და მათემატიკა) განათლების განვითარება საქართველოში ინოვაციის, გუნდურ მუშაობის, ლიდერობისა და მომავალი ლიდერების აღზრდით. ეს მიიღწევა სკოლებში STEM საგნების პოპულარიზაციით, კლუბებისა და რეგიონალური ჰაბების შექმნით, ასევე სკოლის მოსწავლეებისა და მასწავლებლებისთვის პროექტებისა და ღონისძიებების ორგანიზებით. ჩვენი მიზანია ავღზარდოთ პრობლემების კრეატიულად გადამჭრელი, ლიდერებისა და ისეთი ინდივიდუალების თაობა, რომლებიც გაერთიანდებიან საერთო მომავლის შესაქმნელად.",
                moto: "ჩვენ ხელს ვუწყობთ STEM განათლებას",
                objectives: "ჩვენი მიზნები",
                objectiveOne: "STEM განათლების ხელშეწყობა - მოტივაციას ვაძლევთ საქართველოს სკოლის მოსწავლეებს STEM საგნების მიმართულებით ინოვაციებისა და შემეცნებითი საგანმანათლებლო პროგრამების შეთავაზებით.",
                objectiveTwo: "ვაორგანიზებთ ეროვნულ და საერთაშორისო STEM შეჯიბრებს მოსწავლეებისთვის, რომ მათ ჰქონდეთ შესაძლებლობა წარმოაჩინონ თავიანთი ცოდნა, ნიჭი, და კრიტიკული აზროვნების უნარები. მაგალითად, ეს შეჯიბრებია: FIRST Global Challenge, FIRST Robotics Challenge და FIRST Lego League.",
                objectiveThree: "პარტნიორობა - კოლაბორაცია სახელმწიფო სტრუქტურებთან, საგანმანათლებლო დაწესებულებთან და ინდუსტრიულ კომპანიებთან, რომ გავამყაროთ STEM განათლებაში ინიციატივების გავლენა საზოგადოებაზე.",
                objectiveFour: "საზოგადოების ჩართულობა - ვატარებთ პროგრამებს STEM-ის მხარდასაჭერად და ცნობიერების ასამაღლებლად მშობლების, მასწავლებლებისა და საზოგადოების ჩართულობით.",

                core: "ჩვენი მთავარი ღირებულებები",
                coreOne: "ინოვაცია",
                coreTwo: "ინკლუზიურობა",
                coreThree: "კოლაბორაცია",
                coreFour: "ერთინობა",

                coreOneDesc: "ვახალისებთ შემოქმედებითი და ლიმიტების ფარგლებს გარეთ აზროვნებას.",
                coreTwoDesc: "ვუზრუნველყოფთ თანაბარ ხელმისაწვდომობასა და შესაძლებლობებს ყველასთვის, განურჩევლად წარმომავლობისა და სქესისა.",
                coreThreeDesc: "ხელს ვუწყობთ გუნდურ მუშაობასა და თანამშრომლობას მოსწავლეებს, პედაგოგებსა და ინდუსტრიის პროფესიონალებს შორის.",
                coreFourDesc: "ვიცავთ უმაღლეს ეთიკურ სტანდარტებს ყველა ჩვენს საქმიანობაში.",

                carouselUg: "50 მოსწავლემ და მასწავლებელმა საქართველოს სკოლებიდან მონაწილეობა მიიღეს ‘მოსწავლეთა STEM შეჯიბრში’. მათ შექმნეს ეფექტური ჰიდროპონიკური სისტემა სკოლებისთვის. ჩვენმა სპონსორებმა დააჯილდოვეს ყველა მონაწილე, ხოლო სამ გამარჯვებულ გუნდს გადაეცა სპეციალური საჩუქარი.",
                carouselFirst: "ჩვენი გუნდის წევრებმა მონაწილეობა მიიღეს FIRST Global-ზე სინგაპურში. ჩვენი პარტნიორი ორგანიზაციის მხარდაჭერით მათ ჰქონდათ შესაძლებლობა მონაწილეობა მიეღოთ ამ პრესტიჟური ინჟინერიის შეჯიბრის საერთაშორისო ეტაპზე.",
                carouselNasa: "ალექსანდრე ქართველის სახელობის რობოტიკის შეჯიბრის გამარჯვებულმა სამმა მოსწავლესა (რომელთა შორის ჩვენი ორგანიზაციის დირექტორიც იყო) და ერთ ფიზიკის მასწავლებელს ჰქონდა უნიკალური შესაძლებლობა დაეთვალიერებინათ კალიფორნიის ტექნოლოგიების ინსტიტუტი და NASA-ს JPL.",
                carouselCern: "ჩვენმა გუნდმა დააორგანიზა და მოიზიდა ფინანსები 15 მოსწავლისა და მასწავლებლის 2023 წლის ივნისის თვეში CERN-ის ლაბორატორიაში ვიზიტისთვის.",

                workHeadline: "ჩვენი გზა დღემდე და სამომავლო გეგმები",
                workIntro: "გამარჯობა, ჩვენ ინოვაციის ასოციაციის წევრები ვართ. მოკლედ მოგითხრობთ ჩვენ შესახებ. მიუხედავად იმისა, რომ ასოციაცია ახალბედა ორგანიზაციაა, ჩვენს გუნდს მრავალწლიანი და დიდი გამოცდილება აქვს ამ სფეროში. ჩვენ ბოლო ორი წლის განმავლობაში დაორგანიზებული და წარმატებით ჩატარებული გვაქვს არაერთი ეროვნული თუ საერთაშორისო პროექტი.",

                studentsForStudents: "ჩვენ ვართ საქართველოს მოსწავლეების მიერ შექმნილი ორგანიზაცია მოსწავლეებისთვის, დონორი ორგანიზაციების არანაირი მხარდაჭერით.",
                ourProjects: "ესაა ჩვენი გუნდის მიღწევების ჩამონათვალი დღემდე:",

                STEMConference: "მოსწავლეთა STEM კონფერენცია ჩატარდა 2022 წლის დეკემბრის თვეში. ამ პროექტში მონაწილეობა მიიღო საქართველოს ყველა რეგიონის 100-ზე მეტმა მოსწავლე-მასწავლებელმა. მათ ჰქონდათ შესაძლებლობა თბილისში დაეთვალიერებინათ სამეცნიერო მუზეუმები და ლაბორატორიები. მათ ასევე სიტყვით ონლაინ მიმართა NASA-ს JPL-ის მთავარმა ინჟინერმა ბატონმა რობ მეინინგმა. კონფერენცია სამ დღიანი იყო და მონაწილეებს დაუფინანსდათ ყველანაირი ხარჯი.",
                STEMCompetition: "მოსწავლეთა STEM კონკურსში მონაწილეობა მიიღო საქართველოს 50-მდე მოსწავლე-მასწავლებელმა. კონკურსის ფარგლებში უნდა შეექმნათ ეფექტური და ბიუჯეტური ჰიდროპონიკური სისტემა სკოლისთვის. კონკურსის შედეგად გამოვლინდა სამი გამარჯვებული გუნდი, რომლებიც მენტორებთან ერთად ეწვივნენ CERN-ის ლაბორატორიას ჟენევაში, შვეიცარია. კონკურსის ფარგლებში ყველა საჭირო ხარჯი დაფარა საორგანიზაციო ჯგუფმა.",
                CERNInfo: "საქართველოს 15 მოსწავლე-მასწავლებელს სხვადასხვა რეგიონიდან მიეცათ შესაძლებლობა სამი დღის განმავლობაში ევროპის ბირთვული კვლეის ცენტრისთვის (CERN) ესტუმრათ. მათ დაათვალიერეს ცენტრის სხვადასხვა ლაბორატორიები და ქალაქის ღირსშესანიშნაობები. პროგრამის ფარგლებში დაფინანსდა მონაწილეთა შიდა და საერთაშორისო მგზავრობა, სასტუმრო და სხვა ყოველდღიური ხარჯები.",
                FIRSTInfo: "ჩვენი ორგანიზაციის გუნდის სამ წევრს მონაწილეობა აქვს მიღებული ყველაზე პრესტიჟურ საერთაშორისო ინჟინერიის შეჯიბრ FIRST Global-ში სინგაპურში. პროექტში მონაწილეობას იღებდა მსოფლიოს 191 ქვეყანა და თითოეული ქვეყნიდან მხოლოდ ერთი ნაკრები იღებს მონაწილეობას. მათ ევალებათ კოლაბორაციის, ინჟინრული და კრიტიკული აზროვნების უნარებით აღმოფხვრან გლობალური პრობლემა. დონორების დახმარებით, ნაკრებმა შეძლო ამ კონკურსში მონაწილეობის მიღება.",
                futurePlans: "ჩვენ არ ვჩერდებით, წინ უფრო დიდი და საინტერესო მომავალია! შემოგვიერთდით, თვალი ადევნეთ ჩვენს ვებ გვერდს და სოციალური ქსელებს, რომ არ გამოტოვოთ უნიკალური შესაძლებლობები შენთვის ან შენთვის საყვარელი ადამიანისთვის (ან ორივესთვის!) STEM სფეროში.",

                meetOurTeam: "გაიცანით ჩვენი გუნდი",

                teamMemberOneName: "ნიკოლოზ",
                teamMemberOneLastName: "ლობჟანიძე",
                teamMemberOneBiography: "აქ დაიწერება ბიოგრაფია",
                teamMemberOneEmail: "nlobzhanidze@a-innovations.org",
                teamMemberOneImgUrl: "nika_headshot.jpg",
                teamMemberOnePosition: "თანადამფუძნებელი, დირექტორი",

                teamMemberTwoName: "რიჩარდ",
                teamMemberTwoLastName: "რუბინი",
                teamMemberTwoBiography: "აქ დაიწერება ბიოგრაფია",
                teamMemberTwoEmail: "rrubin@a-innovations.org",
                teamMemberTwoImgUrl: "rubin_headshhot.jpg",
                teamMemberTwoPosition: "თანადამფუძნებელი, ფინანსური დირექტორი",

                news: "სიახლეები",
                readMore: "მეტი",
                closeBtn: "დახურვა",

                contactHead: "კონტაქტი",
                monday: "ორშაბათი",
                tuesday: "სამშაბათი",
                wednesday: "ოთხშაბათი",
                thursday: "ხუთშაბათი",
                friday: "პარასკევი",
                saturday: "შაბათი",
                sunday: "კვირა",
                closed: "უქმე",
                email: "ელექტრონული ფოსტა",
                mobile: "ნომერი",
                socNet: "ჩვენი სოციალური ქსელები",

                regards: "განსაკუთრებული მადლობა",
                aka: "ალექსანდრე ქართველის ასოციაცია",
                ug: "საქართველოს უნივერსიტეტი",

                rights: "ყველა უფლება დაცულია © 2024"
            },
        },
        en: {
            translation: {
                name: "N(N)LE Association of Innovations",

                about: "Mission Statement",
                members: "Team Members",

                aboutCompany: "Our mission is to inspire and grow a passion for STEM (science, technology, engineering, and mathematics) education in Georgia and foster innovation, teamwork, leadership, and raise future leaders. It will be achieved with the promotion of STEM subjects in schools, implementing clubs and regional hubs, also by organizing projects and events for school students, teachers, and administration in the field of STEM. Our goal is to develop a new generation of creative problem solvers, leaders, and individuals who will passionately, curiously, and relentlessly pursue excellence in shaping the future.",
                moto: "Promoting STEM Education",
                objectives: "Our Objectives",
                objectiveOne: "Promote STEM Education - Encourage students in Georgian schools to have a passion for STEM subjects by implementing innovative and captivating educational programs.",
                objectiveTwo: "Organize STEM-related competitions nationally and internationally that provide a platform for students to showcase their knowledge, talent, and problem-solving skills including the FIRST Global Challenge, FIRST Robotics Challenge, and FIRST Lego League.",
                objectiveThree: "Forge Partnerships - Collaborate with government bodies, educational institutions, and industry partners to strengthen the impact of STEM education initiatives.",
                objectiveFour: "Community Engagement - Conduct outreach programs to support and grow awareness of the value of STEM by involving parents, educators, and the community.",

                core: "Our Core Values",
                coreOne: "Innovation",
                coreTwo: "Inclusivity",
                coreThree: "Collaboration",
                coreFour: "Integrity",

                coreOneDesc: "Encourage creativity and out-of-the-box thinking.",
                coreTwoDesc: "Ensure equal access and opportunities for all, regardless of background or gender.",
                coreThreeDesc: "Foster teamwork and cooperation among students, educators, and industry professionals.",
                coreFourDesc: "Uphold the highest ethical standards in all organizational activities.",

                carouselUg: "50 students and teachers from Georgian schools participated in 'Students' STEM Competition.' They created an effective hydroponic system for schools. Everyone was prized by sponsors, and three winning teams received a special prize.",
                carouselFirst: "Members of our team participated in FIRST Global Singapore. With the help of our partner organization, there participation on the international level become a reality.",
                carouselNasa: "Winners of the Alexander Kartveli Robotics Competition, which included three students (including our organization's director) and one science teacher had an extraordinary chance to visit CalTech and NASA's JPL.",
                carouselCern: "Our team organized and raised funds for fifteen students and teachers to visit CERN's laboratory in June 2023",

                workHeadline: "Our Path Until Today and Future Plans",
                workIntro: "Hello, we are the Association of Innovations members. We are going to tell a bit about us. Despite the association being a young organization in Georgia, our team has a big and multi-year experience in this field. We organized and made numerous successful national and international projects in the last two years.",

                studentsForStudents: "We are an organization created by students for students and organically grown in Georgia with NO support so far from donor agencies.",
                ourProjects: "Here are some of our accomplishments so far:",

                STEMConference: "Students’ STEM Conference took place in December 2022, in Tbilisi. More than 100 high school students and teachers from around the country. They had an opportunity to visit science museums and laboratories in Tbilisi. NASA’s JPL’s Chief Engineer Mr. Robert Manning addressed participants online. The conference was three days long and the organizing team covered every necessary expense.",
                STEMCompetition: "50 students and teachers participated in the Students’ STEM Competition. In the competition groups of three students created an efficient hydroponic system for schools. After the competition, the jury pronounced three winning teams, who visited the CERN laboratory with their mentors in Geneva, Switzerland. Every necessary expense was covered.",
                CERNInfo: "15 students and teachers from different regions of Georgia had an extraordinary opportunity to visit CERN. They visited different laboratories in the center and visited the city’s numerous historical places. Airfare, transportation, hotel, and everyday expenses were covered.",
                FIRSTInfo: "Three members of our team have participated in the most prestigious international engineering competition FIRST Global in Singapore. 191 countries participated in the competition, one representative team from each. They have a task to use collaborative, engineering, and critical thinking skills to eliminate a global problem. Thanks to our sponsors, Team Georgia had an opportunity to participate in this project.",
                futurePlans: "We are not going to stop, there is a bigger and more successful future ahead! Join us, check our website and social media channels not to miss extraordinary opportunities for you or your loved ones (or for both of you!) to engage in the STEM field.",

                meetOurTeam: "Meet our team!",

                teamMemberOneName: "Nikoloz",
                teamMemberOneLastName: "Lobzhanidze",
                teamMemberOneBiography: "Biography",
                teamMemberOneEmail: "nlobzhanidze@a-innovations.org",
                teamMemberOneImgUrl: "nika_headshot.jpg",
                teamMemberOnePosition: "Co-founder, CEO",

                teamMemberTwoName: "Richard",
                teamMemberTwoLastName: "Rubin",
                teamMemberTwoBiography: "Biography",
                teamMemberTwoEmail: "rrubin@a-innovations.org",
                teamMemberTwoImgUrl: "rubin_headshhot.jpg",
                teamMemberTwoPosition: "Co-founder, CFO",

                news: "News",
                readMore: "Read More",
                closeBtn: "Close",

                contactHead: "Contact",
                monday: "Monday",
                tuesday: "Tuesday",
                wednesday: "Wednesday",
                thursday: "Thursday",
                friday: "Friday",
                saturday: "Saturday",
                sunday: "Sunday",
                closed: "Closed",
                email: "E-mail",
                mobile: "Mobile Number",
                socNet: "Our social media channels",

                regards: "Special Thanks",
                aka: "Alexander Kartveli Association",
                ug: "The University of Georgia",

                rights: "All rights reserved © 2024"
            },
        }
    }
})
