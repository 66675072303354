import SwipeableTextMobileStepper from "./carousel.jsx";
import ResponsiveAppBar from "./header";
import "./style.css";
import OurTeam from "./ourTeam";
import Newsletter from "./newsletter";
import AboutUs from "./aboutUs";
import ContactUs from "./contactUs";
import Footer from "./footer";
import OurWork from "./ourWork";
import Sponsors from "./sponsors";

function HomePage() {
    return <>
        <ResponsiveAppBar/>
        <SwipeableTextMobileStepper/>
        <AboutUs/>
        <OurWork/>
        <OurTeam/>
        <Newsletter/>
        <ContactUs/>
        <Sponsors/>
        <Footer/>
    </>
}

export default HomePage;