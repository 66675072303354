import React from 'react';
import {useTranslation} from "react-i18next";

function AboutUs() {
    const { t, i18n } = useTranslation();
    return <>
        <div className={`about-company ${i18n.language}`} id={"about-us"}>
            <br/>
            <h1>{t("moto")}</h1>
            <div className="mission">
                <img src="./AI.png" alt="" className="mission-logo"/>
                <div className="">
                    <p className="mission-text">
                        {t("aboutCompany")}
                    </p>
                </div>
            </div>
            <br/>
        </div>
        <div className="statements">
            <div className="objectives">
                <h3 style={{textAlign: "center"}}>{t("objectives")}</h3>
                <ol>
                    <li>{t("objectiveOne")}</li>
                    <li>{t("objectiveTwo")}</li>
                    <li>{t("objectiveThree")}</li>
                    <li>{t("objectiveFour")}</li>
                </ol>
            </div>
            <div className="values">
                <h3 style={{textAlign: "center"}}>{t("core")}</h3>
                <ul>
                    <li><b>{t("coreOne")}</b> - {t("coreOneDesc")}</li>
                    <li><b>{t("coreTwo")}</b> - {t("coreTwoDesc")}</li>
                    <li><b>{t("coreThree")}</b> - {t("coreThreeDesc")}</li>
                    <li><b>{t("coreFour")}</b> - {t("coreFourDesc")}</li>
                </ul>
            </div>
        </div>
    </>
}

export default AboutUs;