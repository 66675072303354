import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import usePagination from '../assets/pagination';
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import db from "../firebase/firebase";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import NewsPost from "../assets/newsPost";
import { convert } from 'html-to-text';

function Newsletter() {
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(0);
    const [posts, setPosts] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(query(collection(db, "posts"), orderBy("timestamp_sort", "desc")));
                const fetchedPosts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPosts(fetchedPosts);
                setSize(fetchedPosts.length);
                setIsDataFetched(true);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        };

        if (!isDataFetched) {
            fetchData();
        }
    }, [isDataFetched]);


    const _DATA = usePagination(posts, 3);
    const count = Math.ceil(size / 3);

    const handleChange = (event, value) => {
        setPage(value);
        _DATA.jump(value);
    };

    return (
        <>
            <div className="news-container" id="news">
                <hr />
                <h1 style={{ textAlign: "center" }}>{t("news")}</h1>
                <div className="pag">
                    <Pagination
                        count={count}
                        size="large"
                        page={page}
                        color="primary"
                        onChange={handleChange}
                    />
                </div>
                <br />
                <div className="news">
                    {_DATA.currentData().map((v) => (
                        <Card sx={{ maxWidth: 345 }} className={"news-card"} key={v.id}>
                            <CardMedia
                                component="img"
                                alt="post image"
                                height="140"
                                style={{ objectFit: "cover" }}
                                image={v.img_url}
                            />
                            <CardContent>
                                <small>{v.timestamp}</small>
                                <Typography gutterBottom variant="h5" component="div">
                                    {v[`title_${i18n.language}`] && v[`title_${i18n.language}`].length > 40 ? v[`title_${i18n.language}`].substring(0, 150) + "..." : v[`title_${i18n.language}`]}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {v[`text_${i18n.language}`] && convert(v[`text_${i18n.language}`], {wordwrap: 150}).substring(0, 150) + "..."}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <NewsPost id={v.timestamp_sort} />
                            </CardActions>
                        </Card>
                    ))}
                </div>
                <br />
                <div className="pag">
                    <Pagination
                        count={count}
                        size="large"
                        page={page}
                        color="primary"
                        onChange={handleChange}
                    />
                </div>
                <br />
                <hr />
            </div>
        </>
    );
}

export default Newsletter;
