import React from 'react';
import {useTranslation} from "react-i18next";

function OurTeam() {
    const { t, i18n } = useTranslation();
    const team = [
        {name: t("teamMemberOneName"), lastName: t("teamMemberOneLastName"), biography: t("teamMemberOneBiography"), email: t("teamMemberOneEmail"), imgUrl: t("teamMemberOneImgUrl"), position: t("teamMemberOnePosition"), imgStyle: "left"},
        {name: t("teamMemberTwoName"), lastName: t("teamMemberTwoLastName"), biography: t("teamMemberTwoBiography"), email: t("teamMemberTwoEmail"), imgUrl: t("teamMemberTwoImgUrl"), position: t("teamMemberTwoPosition"), imgStyle: "center"},
    ];

    return <>
        <div className="team" id="team">
            <h1 style={{textAlign: "center"}}>{t("meetOurTeam")}</h1>
            <div className="team-container">
                {team.map((member, index) => (
                    <div key={index} className={`team-card ${i18n.language}`}>
                        <img src={`${member.imgUrl}`} className="user-image" alt="" style={{objectPosition: member.imgStyle}}/>
                        <div className="member-info">
                            <h2>{member.name} {member.lastName}</h2>
                            <i>{member.position}</i>
                            <p><a href={`mailto:${member.email}`}>{member.email}</a></p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </>
}

export default OurTeam;