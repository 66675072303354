import React from 'react';
import {useTranslation} from "react-i18next";

function Footer() {
    const { t } = useTranslation();
    return <>
        <div className="footer">
            <p className="company">{t("name")}</p>
            <p className="rights">{t("rights")}</p>
        </div>
    </>
}

export default Footer;