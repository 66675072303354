import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { collection, query, where, getDocs } from "firebase/firestore";
import db from "../firebase/firebase";
import { useState } from "react";

export default function NewsPost({ id }) {
    const [open, setOpen] = React.useState(false);
    const [posts, setPost] = useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();

    const handleClickOpen = async () => {
        setOpen(true);
        const q = query(collection(db, "posts"), where("timestamp_sort", "==", id));

        const querySnapshot = await getDocs(q);
        const fetchedPosts = [];
        querySnapshot.forEach((doc) => {
            fetchedPosts.push(doc.data());
        });
        setPost(fetchedPosts);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen}>
                {t("readMore")}
            </Button>
            {
                posts.map((post, index) => {
                    return (
                        <Dialog
                            key={index}
                            fullScreen={fullScreen}
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">
                                {post[`title_${i18n.language}`]}
                            </DialogTitle>
                            <DialogContent>
                                <img src={post.img_url || "https://a-innovations.org/AI.png"} alt={post[`title_${i18n.language}`]} style={{ width: "100%", height: "300px", objectFit: "cover" }} />
                                <small>{post.timestamp}</small>
                                <DialogContentText
                                    dangerouslySetInnerHTML={{ __html: post[`text_${i18n.language}`] }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} autoFocus>
                                    {t("closeBtn")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    );
                })
            }
        </React.Fragment>
    );
}
