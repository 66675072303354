import './App.css';
import HomePage from './homePage/index.jsx';
import './i18n.js';
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import React from "react";
import Link from "@mui/material/Link";
import {hover} from "@testing-library/user-event/dist/hover";

function App() {
    const { t, i18n } = useTranslation();
    const styles = {
        link: {
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: '1000',
            backgroundColor: "white",
            borderRadius: "50% 50%",
        },
        icon: {
            color: 'blue',
            fontSize: '50px',
        },
    };
    return (
        <div className={`App ${i18n.language}`}>
            <Helmet>
                <title>
                    {i18n.language === 'ka' ? 'ინოვაციების ასოციაცია' : 'Association of Innovations'}
                </title>
            </Helmet>
            <HomePage/>
            <Link target="_blank" href="https://www.facebook.com/AI.IA.GEO" position="fixed" style={styles.link}>
                <IconButton aria-label="Facebook Page">
                    <FacebookOutlinedIcon style={styles.icon}/>
                </IconButton>
            </Link>
        </div>
    );
}

export default App;
