import React from 'react';
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";

function OurWork() {
    const { t } = useTranslation();
    return <>
        <div className="work-container">
            <br/>
            <h1 style={{textAlign: "center"}}>{t("workHeadline")}</h1>
            <p style={{fontSize: "17px"}}>{t("workIntro")}</p>
            <p style={{fontSize: "17px"}}>{t("studentsForStudents")}</p>
            <p style={{fontSize: "17px"}}>{t("ourProjects")}</p>
            <br/>
            <div className="projects">
                <div className="container">
                    <img src="STEM-conference.jpg" alt="Students' STEM Conference" className="image"/>
                    <div className="overlay">
                        <div className="text">{t("STEMConference")}</div>
                    </div>
                </div>
                <div className="container">
                    <img src="STEM-competition.jpg" alt="Students' STEM Competition" className="image"/>
                    <div className="overlay">
                        <div className="text">{t("STEMCompetition")}</div>
                    </div>
                </div>
                <div className="container">
                    <img src="CERN.JPG" alt="Visit to CERN Laboratory" className="image"/>
                    <div className="overlay">
                        <div className="text">{t("CERNInfo")}</div>
                    </div>
                </div>
                <div className="container">
                    <img src="FIRST-representing.jpg" alt="FIRST Global" className="image"/>
                    <div className="overlay">
                        <div className="text">{t("FIRSTInfo")}</div>
                    </div>
                </div>
            </div>
            <br/>
            <p style={{fontSize: "17px"}}>{t("futurePlans")}</p>
        </div>
    </>
}

export default OurWork;