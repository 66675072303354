import React from 'react';
import {useTranslation} from "react-i18next";

function Sponsors() {
    const { t } = useTranslation();
    return <>
        <div className="sponsors">
            <h1 style={{textAlign: "center"}}>{t("regards")}</h1>
            <div className="sponsors-info">
                <div className="sponsor">
                    <a target={`_blank`} href="https://www.alexanderkartveli.com/"><img src="aka-logo.jpg" alt="The Alexander Kartveli Association - logo" className="sponsor-logo"/></a>
                        <h3 className="sponsor-name"><a target={`_blank`} href="https://www.alexanderkartveli.com/">{t("aka")}</a></h3>
                </div>
                <div className="sponsor">
                    <a target={`_blank`} href="https://ug.edu.ge/"><img src="ug-logo.png" alt="The University of Georgia - logo" className="sponsor-logo" style={{width: "200px"}}/></a>
                    <h3 className="sponsor-name"><a target={`_blank`} href="https://ug.edu.ge/">{t("ug")}</a></h3>
                </div>
            </div>
        </div>
    </>
}

export default Sponsors;